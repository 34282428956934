<template>
  <div>
    <StockPurchaseOrderListByStatus :statusId="statusId" :pageTitle="pageTitle">
    </StockPurchaseOrderListByStatus>
  </div>
</template>
<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

import StockPurchaseOrderListByStatus from "@/modules/stock/components/purchase-orders/StockPurchaseOrderListByStatus.vue";

import _ from "lodash";
export default {
  name: "StockPurchaseOrderListReady",
  mixins: [permissionMixin],
  data() {
    return {
      statusId: 3,
      pageTitle: this.$t("LIST_READY_STOCK_PURCHASE_ORDERS")
    };
  },
  components: { StockPurchaseOrderListByStatus },
  watch: {},
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ready Stock Purchase Orders List" }
    ]);
  },
  computed: {},
  methods: {}
};
</script>
